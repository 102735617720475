<template>
  <div class="">
    <div class="">
      <b-tabs content-class="pt-1">
        <b-tab active title="Tersedia"> <Tersedia /> </b-tab>
        <b-tab title="Tryout Saya"> <TryoutSaya /> </b-tab>
      </b-tabs>
    </div>
  </div>
</template>

<script>
import { BTabs, BTab, BCardText } from "bootstrap-vue";
import Tersedia from "./components/Tersedia";
import TryoutSaya from "./components/TryoutSaya";

export default {
  components: {
    BTabs,
    BTab,
    BCardText,
    Tersedia,
    TryoutSaya,
  },
};
</script>
